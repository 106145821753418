import { Dialog, Transition } from "@headlessui/react";
import React, { useRef, useState } from "react";
import tw, { css } from "twin.macro";

import Button from "./Button";

const modal = css`
  ${tw`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-scroll`}
  height: 625px;
  @media only screen and (max-height: 760px) {
    height: 90vh;
  }
`;

export const useModal = <Params extends {} | null>(isInitiallyOpen = false) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [params, setParams] = useState<Params | null>(null);
  return {
    isOpen,
    open: (inputParams: React.SetStateAction<Params | null> = null) => {
      setParams(inputParams);
      return setIsOpen(true);
    },
    close: () => {
      setParams(null);
      return setIsOpen(false);
    },
    params
  };
};

interface Props {
  header?: string;
  usedModal: any;
  confirmTitle?: string;
  cancelTitle?: string;
  icon?: string;
  maxWidth?: string;
  height?: string;
  overflowY?: string;
  maxHeight?: string;
}

const Modal: React.FC<Props> = ({
  header,
  children,
  confirmTitle,
  cancelTitle,
  icon,
  usedModal,
  maxWidth,
  height,
  maxHeight,
  overflowY,
  ...rest
}) => {
  const cancelButtonRef = useRef(null);
  const modifiedWidth = maxWidth ? { maxWidth } : {};
  const modifiedHeight = height ? { height } : {};
  const modalOverflowY = overflowY ? { overflowY } : {};
  const modalMaxHeight = maxHeight ? { maxHeight } : {};

  return (
    <Transition.Root tw={"sm:max-w-xl"} show={usedModal.isOpen} {...rest}>
      <Dialog
        as="div"
        static={true}
        tw="fixed z-30 inset-0"
        initialFocus={cancelButtonRef}
        open={usedModal.isOpen}
        onClose={usedModal.close}
      >
        <div tw="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:flex sm:p-0">
          <Dialog.Overlay tw="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span tw="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div
            tw="inline-block bg-white rounded-lg text-left sm:overflow-hidden shadow-xl transform sm:my-8 sm:max-w-xl duration-500"
            style={{ ...modifiedWidth, ...modalOverflowY, ...modalMaxHeight } as React.CSSProperties}
          >
            <div css={modal} style={modifiedHeight} tw="">
              <div tw="sm:flex sm:items-start">
                <div tw="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 tw="text-lg font-medium text-lg text-gray-900">{header}</h3>
                  <div tw="mt-2">{children}</div>
                </div>
              </div>
            </div>
            <div tw="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse">
              {confirmTitle && (
                <Button
                  isSecondary={true}
                  tw="rounded-md border border-transparent shadow-sm px-4 py-2 focus:(outline-none ring-2 ring-offset-2 ring-red-500) ml-3 sm:text-sm"
                  onClick={() => usedModal.close()}
                >
                  {confirmTitle}
                </Button>
              )}
              {cancelTitle && (
                <Button
                  tw="rounded-md border border-gray-300 shadow-sm px-4 py-2 text-gray-700 hover:(bg-gray-50 text-black) focus:(outline-none ring-2 ring-offset-2 ring-turquoise-dark) sm:mt-0 sm:ml-3 sm:text-sm"
                  onClick={() => usedModal.close()}
                  ref={cancelButtonRef}
                >
                  {cancelTitle}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
