import tw, { styled } from "twin.macro";

interface Props {
  isPrimary: boolean;
  isSecondary: boolean;
  isOutlined: boolean;
  isBig: boolean;
  onClick: () => void;
}

const Button = styled.button(({ isPrimary, isSecondary, isOutlined, isBig }: Partial<Props>) => [
  tw`font-bold py-3 px-6 uppercase inline-block`,
  tw`transition duration-200 hover:text-white!`,

  isPrimary && tw`bg-turquoise text-white hover:bg-turquoise-dark`,
  isSecondary && tw`bg-red text-white hover:bg-red-dark`,
  isOutlined && tw`border border-red text-red hover:(border-red-dark text-red-dark!)`,
  isBig && tw`text-xl py-4`
]);

export default Button;
